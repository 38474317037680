<script>
import Vue from "vue";
import VueRecaptcha from "vue-recaptcha";
import { tldExists } from 'tldjs'
import duppla from "@/duppla";
import moment from "moment";
import VueMonthlyPicker from "vue-monthly-picker";
import WhatsappWidget from "@/components/whatsapp-widget";
import Cloudinary from "cloudinary-vue";
import {
	setWithExpiration,
	getWithExpiration,
} from "@/services/storageResultsService";

import Navbar from "@/components/navbar";
import Footer from "@/components/footer";

Vue.use(Cloudinary);

import {
	HomeIcon,
	UserIcon,
	UserCheckIcon,
	MailIcon,
	KeyIcon,
	PhoneForwardedIcon,
	PhoneIcon,
	FlagIcon,
	FolderIcon,
	PaperclipIcon,
	XCircleIcon,
	CalendarIcon,
	HashIcon,
	PlusIcon,
	XIcon,
	ArrowLeftIcon,
	ArrowRightIcon,
	MicIcon,
	MicOffIcon,
	StopCircleIcon,
} from "vue-feather-icons";

const RESULTS_SAVE_TAG = process.env.VUE_APP_RESULTS_SAVE_TAG;

export default {
	/*
	metaInfo: {
		title: 'Checkup Digital de cáncer - '
	},
	*/
	components: {
		Navbar,
		Footer,
		VueRecaptcha,
		VueMonthlyPicker,
		HomeIcon,
		UserIcon,
		UserCheckIcon,
		MailIcon,
		KeyIcon,
		PhoneForwardedIcon,
		PhoneIcon,
		FlagIcon,
		FolderIcon,
		PaperclipIcon,
		XCircleIcon,
		CalendarIcon,
		HashIcon,
		PlusIcon,
		XIcon,
		ArrowLeftIcon,
		ArrowRightIcon,
		MicIcon,
		MicOffIcon,
		StopCircleIcon,
		WhatsappWidget,
	},
	data() {
		return {
			monthLabels: [
				"Ene",
				"Feb",
				"Mar",
				"Abr",
				"May",
				"Jun",
				"Jul",
				"Ago",
				"Sep",
				"Oct",
				"Nov",
				"Dec",
			],
			loadingMedicalRecord: true,
			errorForm: false,
			loadingContactForm: false,
			validOrganization: false,
			validOrganizationAndStart: false,
			savingForm: false,
			savingOk: false,
			wCurrentStep: 0,
			user: { fields: { contact_email: "" } },
			form: {
				symptoms: [],
				direct_relatives: [],
				opt_advice: "yes",
				contact_phone_country: process.env.VUE_APP_DEFAULT_PH_COUNTRY,
			},
			countries: [],
			reCaptchaMissing: false,
			reCaptchaMissingContactForm: false,
			errorContactForm: false,
			successContactForm: false,
			reCaptchaSiteKey: process.env.VUE_APP_RECAPTCHA_KEY,
			organization: null,
			contactForm: {
				email: "",
				comments: "Estoy interesado en el checkup digital de cáncer",
			},
			errorMail: ""
		};
	},
	computed: {
		organizationUuid() {
			return this.$route.query.id;
		},
		subdivision() {
			return this.$route.query.sub;
		},
		reshareOrigin() {
			return this.$route.query.o;
		},
		reshareDepthLevel() {
			return this.$route.query.dl;
		},
		omitIntro() {
			return this.$route.query.oi;
		},
		greeting() {
			const hr = new Date().getHours();
			if (hr >= 0 && hr < 12) {
				return this.$t("morning_greeting");
			} else if (hr >= 12 && hr <= 18) {
				return this.$t("afternoon_greeting");
			} else {
				return this.$t("evening_greeting");
			}
		},
		wSteps() {
			let steps = [
				{
					form: "init",
					submitForm: true,
					text: this.$t("init_text"),
				},
				{
					form: "symptoms_step",
					submitForm: true,
					text: this.$t("symptoms_step_text"),
				},
				{
					form: "ahf",
					submitForm: true,
					text: this.$t("ahf_text"),
				},
				{
					form: "agine",
					submitForm: true,
					text: this.$t("agine_text"),
				},
				{
					form: "apnp",
					submitForm: true,
					text: this.$t("apnp_text"),
				},
				{
					form: "comorbidities",
					submitForm: true,
					text: this.$t("comorbidities_text"),
				},
				{
					form: "end",
					submitForm: true,
					text: "",
				},
			];
			if (
				this.form.patient_sex === "male" ||
				(this.form.patient_sex === "female" && this.form.user_age < 18)
			) {
				steps.splice(3, 1); // agine
			}

			return steps;
		},
		wTotalSteps() {
			return this.wSteps.length - 1;
		},
		wProgress() {
			return (this.wCurrentStep / this.wTotalSteps) * 100;
		},
		wFirstStep() {
			if (this.wCurrentStep === 0) {
				return true;
			} else {
				return false;
			}
		},
		wLastStep() {
			if (this.wCurrentStep === this.wTotalSteps) {
				return true;
			} else {
				return false;
			}
		},
		currYear() {
			return moment().year();
		},
		currDate() {
			return moment().format("YYYY-MM-DD");
		},
	},
	methods: {
		reCaptchaVerified(response) {
			this.form.g_recaptcha_response = response;
		},
		reCaptchaExpired() {
			this.form.g_recaptcha_response = null;
		},
		reCaptchaVerifiedContactForm(response) {
			this.contactForm.g_recaptcha_response = response;
		},
		reCaptchaExpiredContactForm() {
			this.contactForm.g_recaptcha_response = null;
		},
		wStepIndex(currentStep) {
			for (var i = 0; i < this.wSteps.length; i++) {
				if (this.wSteps[i].form === currentStep) {
					return i;
				}
			}
			return 0;
		},
		wStepName(index) {
			return this.wSteps[index].form;
		},
		validateEmail(email) {
			const parts = email.split('@');
			if (parts.length !== 2) return { valid: false, message: 'Formato de correo electrónico inválido.' };
			let [localPart, domain] = parts;

			// Validar caracteres permitidos en la parte local
			if (/[^A-Za-z0-9!#$%&'*+/=?^_`{|}~.-]/.test(localPart)) {
				return { valid: false, message: 'La parte local contiene caracteres no permitidos.' };
			}

			// Verificar puntos en la parte local
			if (localPart.startsWith('.') || localPart.endsWith('.') || localPart.includes('..')) {
				return { valid: false, message: 'La parte local no puede comenzar o terminar con un punto, ni contener puntos consecutivos.' };
			}

			domain = domain.toLowerCase();

			// Validar caracteres permitidos en el dominio
			if (/[^A-Za-z0-9.-]/.test(domain)) {
				return { valid: false, message: 'El dominio contiene caracteres no permitidos.' };
			}

			// Verificar TLD válido
			if (!tldExists(domain)) {
				return { valid: false, message: `El dominio "${domain}" no tiene un TLD válido.` };
			}

			return { valid: true, email: `${localPart}@${domain}`, message: `El dominio "${domain}" tiene un TLD válido.` };
		}
		,
		nextStep() {
			if (this.wCurrentStep < this.wTotalSteps) {
				var stepsForward = 1;
				/*if (this.user.fields.payment && (this.wCurrentStep + 1) == this.wPaymentStep){
					stepsForward = 2
				}*/
				var currentForm =
					this.$refs["form_" + this.wStepName(this.wCurrentStep)];
				if (currentForm) {
					if (currentForm.checkValidity()) {
						console.log("🚀 ~ formData ~ currentForm.elements:", this.wCurrentStep);
						// montón.identify(this.form.contact_email)
						// if (this.wCurrentStep === 0) {
						// 	console.log("🚀 ~ formData ~ currentForm.elements:", this.form.contact_email);
						// 	const isValid = this.validateEmail(this.form.contact_email)
						// 	if (!isValid.valid) {
						// 		this.errorMail = "Asegúrate de que no haya espacios extra, ni caracteres especiales que no correspondan en tu dirección de correo.";
						// 		return false;
						// 	}
						// }

						this.wCurrentStep += stepsForward;
						this.goto("init");
						//Todo se hara en un mismo momento, no es necesario para el cuestionario
						/*if (this.wSteps[originalStep].lastStep) {
							this.form.form_completed_date = new Date()
							this.form.status = '1'
						}
						this.submit().then(() => {
							delete this.form.form_completed_date
							delete this.form.status
							this.getLinkedLists()
						})
						*/
					} else {
						currentForm.reportValidity();
					}
				} else {
					// if no form then nothing to validate, just submit to save step
					this.wCurrentStep += stepsForward;
					this.goto("init");
					//Todo se hara en un mismo momento, no es necesario para el cuestionario
					//this.submit()
				}
			}
		},
		previousStep() {
			if (this.wCurrentStep > 0) {
				var stepsBack = 1;
				/*if (this.user.fields.payment && (this.wCurrentStep - 1) == this.wPaymentStep){
					stepsBack = 2
				}*/
				this.wCurrentStep -= stepsBack;
				this.goto("init");
			}
			return;
		},
		submit() {
			if (!this.savingForm) {
				this.errorForm = false;
				this.savingForm = true;
				this.reCaptchaMissing = false;

				var currentForm = this.$refs["form_end"];

				if (currentForm.checkValidity()) {
					if (!this.form.g_recaptcha_response) {
						this.reCaptchaMissing = true;
						this.savingForm = false;
						return;
					}

					//Object.assign(cleanForm, this.form)
					let cleanForm = JSON.parse(JSON.stringify(this.form));

					if (this.form.opt_advice === "no") {
						delete cleanForm.contact_phone;
						delete cleanForm.contact_phone_country;
					}
					//Airtable linked records
					if (cleanForm.contact_phone_country) {
						cleanForm.contact_phone_country = [cleanForm.contact_phone_country];
					}
					if (cleanForm.patient_country) {
						cleanForm.patient_country = [cleanForm.patient_country];
					}

					// reshare origin
					if (this.reshareOrigin) {
						cleanForm.reshare_origin = [this.reshareOrigin];
					}
					// reshare depth level
					if (this.reshareDepthLevel) {
						if (!isNaN(this.reshareDepthLevel)) {
							cleanForm.reshare_level = Number(this.reshareDepthLevel);
						}
					}

					cleanForm.organization = [this.organization.id];

					if (cleanForm.patient_sex === "male") {
						delete cleanForm.f_menstruation_age;
						delete cleanForm.ivs;
						delete cleanForm.l_menstruation_age;
						delete cleanForm.contraceptives;
						delete cleanForm.pregnant_currently;
						delete cleanForm.previous_pregnancies;
						delete cleanForm.previous_pregnancies_qty;
						delete cleanForm.pregnant_after_thirty;
						delete cleanForm.lactation_months;
						delete cleanForm.vag_bleeding;
						delete cleanForm.breast_mass;
					} else {
						if (cleanForm.ivs === "no") {
							delete cleanForm.pregnant_currently;
							delete cleanForm.previous_pregnancies;
							delete cleanForm.previous_pregnancies_qty;
							delete cleanForm.pregnant_after_thirty;
							delete cleanForm.lactation_months;
							delete cleanForm.vag_bleeding;
						}
						if (cleanForm.previous_pregnancies === "no") {
							delete cleanForm.previous_pregnancies_qty;
							delete cleanForm.pregnant_after_thirty;
							delete cleanForm.lactation_months;
						}
					}

					if (cleanForm.smoke === "no") {
						delete cleanForm.smoke_years;
						delete cleanForm.smoke_qty_day;
						delete cleanForm.ex_smoker;
						delete cleanForm.ex_somker_years;
					}

					if (cleanForm.alcohol === "no") {
						delete cleanForm.alcohol_frequency;
						delete cleanForm.alcohol_amount;
						delete cleanForm.alcohol_years;
					}

					if (cleanForm.exercise === "no") {
						delete cleanForm.exercise_time;
					}

					cleanForm.lang = this.$i18n.locale;
					if (this.subdivision) {
						if (this.subdivision.length === 17) {
							cleanForm.subdivision = [this.subdivision];
						}
					}


					//console.log(JSON.stringify(cleanForm, null, 2))

					duppla({ url: "questionnaire", method: "POST", data: cleanForm })
						.then((response) => {
							// console.log("*******************************************************************")
							// console.log("Almacenado: " + JSON.stringify(response.data, null, 2))
							setWithExpiration(RESULTS_SAVE_TAG, response.data);
							this.$router.push({
								name: "questionnaire-result",
								params: { param: response.data },
							});
						})
						.catch((error) => {
							this.errorForm = true;
							this.gTrackException("questionnaire -> submit: " + error, true);
						})
						.finally(() => {
							this.savingForm = false;
						});
				} else {
					currentForm.reportValidity();
					this.savingForm = false;
				}
			}
		},
		contact() {
			// validar recapctah
			if (!this.loadingContactForm) {
				this.errorContactForm = false;
				this.reCaptchaMissingContactForm = false;
				if (this.$refs.contactForm.checkValidity()) {
					if (!this.contactForm.g_recaptcha_response) {
						this.reCaptchaMissingContactForm = true;
						return;
					}
					this.loadingContactForm = true;

					let cleanForm = JSON.parse(JSON.stringify(this.contactForm));

					if (cleanForm.phone_country) {
						cleanForm.phone_country = [cleanForm.phone_country];
					}

					duppla({ url: "contact", method: "POST", data: cleanForm })
						.then((response) => {
							this.gTrackEvent("contact", "engagement", null);
							this.successContactForm = true;
							//console.log(JSON.stringify(response.data, null, 2))
						})
						.catch((error) => {
							this.errorContactForm = true;
							this.$refs.recaptchaContactForm.reset();
							this.gTrackException("questionnaire -> contact: " + error, false);
						})
						.finally(() => {
							this.loadingContactForm = false;
							//no se requiere el reset debido a que el recaptcha desaparece
							//this.$refs.recaptchaContactForm.reset();
							this.contactForm.g_recaptcha_response = null;
						});
				} else {
					this.$refs.contactForm.reportValidity();
				}
			}
		},
		getCountries() {
			duppla({ url: "countries", method: "GET" }).then((response) => {
				this.countries = response.data;
			});
		},
		getOrganization(uuid) {
			return new Promise((resolve) => {
				duppla({ url: "organization?uuid=" + uuid, method: "GET" })
					.then((response) => {
						this.organization = response.data;
						// console.log(JSON.stringify(this.organization, null, 2))
						if (this.omitIntro) {
							this.startQuestionnaire()
						}
						this.loadingMedicalRecord = false;
						this.validOrganization = true;
					})
					.catch(() => { })
					.finally(() => {
						this.loadingMedicalRecord = false;
						resolve();
					});
			});
		},
		generateAlphanumericId() {
			const characters =
				"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
			return Array.from({ length: 10 }, () =>
				characters.charAt(Math.floor(Math.random() * characters.length))
			).join("");
		},
		startQuestionnaire() {
			if (this.organization) {
				this.validOrganizationAndStart = true;
				heap.identify(this.generateAlphanumericId());
			}
		},
		directRelativeChange() {
			if (
				this.form.direct_relative === "no" ||
				this.form.direct_relative === "idk"
			) {
				if (this.form.direct_relatives) {
					this.form.direct_relatives.splice(0);
				}
			} else {
				if (this.form.direct_relatives.length === 0) {
					this.addFormListItem("direct_relatives");
				}
			}
		},
		symptomChange() {
			// console.log('changing kps: ' + this.form.scale_kps)
			if (this.form.symptom_concern === "no") {
				if (this.form.symptoms) {
					this.form.symptoms.splice(0);
				}
			} else {
				// console.log('this is the current length fo symtpoms:' + this.form.symptoms.length)
				if (this.form.symptoms.length === 0) {
					this.addFormListItem("symptoms");
				}
			}
		},
		addFormListItem(list) {
			let item = {
				fields: {},
			};

			// select form inputs
			if (list === "direct_relatives") {
				item.fields.disease = null;
				item.fields.relative = null;
				// item.fields.alive = null
				item.fields.diagnosis_age = null;
			}
			this.form[list].push(item);
		},
		removeFormListItem(list, index) {
			if (this.form[list][index].id) {
				if (!this.form[list + "_delete"]) {
					this.form[list + "_delete"] = [];
				}
				this.form[list + "_delete"].push(this.form[list][index].id);
				this.form[list].splice(index, 1);
			} else {
				this.form[list].splice(index, 1);
			}
		},
		getLinkedLists() {
			let fields = "?";

			for (var i = 0; i < this.linkedLists.length; i++) {
				fields += "&fields%5B%5D=" + this.linkedLists[i];
			}
			// console.log('getLinkedLists: ' + fields)
			duppla({
				url: "my-medical-record" + fields,
				method: "GET",
				data: null,
			}).then((response) => {
				// console.log(JSON.stringify(response.data.fields, null, 2))
				for (var i = 0; i < this.linkedLists.length; i++) {
					this.form[this.linkedLists[i]] =
						response.data.fields[this.linkedLists[i]];
					delete this.form[this.linkedLists[i] + "_delete"];
				}
			});
		},
		goto(refName) {
			var element = this.$refs[refName];
			var top = element.offsetTop;
			window.scrollTo(0, top);
		},
		loadHbspt(uuid) {
			return new Promise((resolve) => {
				const script = document.createElement("script");
				script.src = "https://js.hsforms.net/forms/embed/v2.js";
				document.body.appendChild(script);
				script.addEventListener("load", () => {
					//console.debug("hbspt script loaded")
					resolve(event);
				});
			});
		},
		runPromises() {
			let p1 = this.getOrganization(this.organizationUuid);
			let p2 = this.loadHbspt();
			this.getCountries();

			Promise.all([p1, p2]).then((data) => {
				if (window.hbspt && !this.validOrganization) {
					console.debug("hbspt valid, creating form");
					try {
						if (this.$i18n.locale === "es") {
							window.hbspt.forms.create({
								region: "na1",
								portalId: "39774904",
								formId: "a465be13-68e3-4c96-ae73-1dbccb8d2c1d",
								target: "#hubspotForm",
							});
						} else {
							window.hbspt.forms.create({
								region: "na1",
								portalId: "39774904",
								formId: "4c5a7dd4-31e0-4c54-803a-3c7781c682c6",
								target: "#hubspotForm",
							});
						}
					} catch (e) {
						console.error("Creating hbspt form");
						console.error(e);
					}
				}
			});
		},
	},
	created() {
		this.runPromises();
		const result = getWithExpiration(RESULTS_SAVE_TAG);
		// console.log("🚀 ~ created ~ result:", result);
		if (result) {
			this.$router.push({
				name: "questionnaire-result",
				params: { param: result },
			});
		}
		// console.log("🚀 ~ created ~ sub:", this.subdivision);
	},
	watch: {
		"$i18n.locale": function (newLocale, oldLocale) {
			// console.log(`Idioma cambiado de ${oldLocale} a ${newLocale}`);
			this.runPromises();
		},
	},
};
</script>
<style type="text/css">
.custom-control-label {
	cursor: pointer;
}

.remove-item--btn {
	display: flex;
	width: 35px;
	height: 35px;
	margin: 0;
	padding: 0;
	align-items: center;
	justify-content: center;
	border-radius: 100%;
}

.remove-item--btn svg {
	width: 20px;
	height: 20px;
}

.row--input.comorbidities {
	gap: 0;
}
</style>

<template>
	<div ref="init">
		<Navbar />
		<section class="container--forms" v-if="!validOrganizationAndStart">
			<div class="container">
				<div class="row justify-content-center" v-if="loadingMedicalRecord">
					<div class="col-12 text-center">
						<p v-t="'validation.validating_access'"></p>
						<br /><br />
						<b-spinner style="width: 3rem; height: 3rem" variant="primary" label="Cargando..."></b-spinner>
					</div>
				</div>
				<span v-if="validOrganization && !loadingMedicalRecord">
					<div class="row justify-content-center">
						<div class="col-12 text-center">
							<div class="section-title mb-1 pb-2">
								<h3 class="mb-4 title--home" v-t="'validation.start_questionnaire_title'"></h3>
								<p class="text-muted para-desc mx-auto mb-0" v-html="$t('validation.start_questionnaire_description')">
								</p>
							</div>
						</div>
						<div class="col-12 text-center">
							<p class="para-desc mx-auto mb-0">
								<span v-t="'validation.free_thanks_to'"></span>
								<span v-if="
									!organization.fields.img_publicid ||
									!organization.fields.img_version
								">
									<br /><br />
									<h5>{{ this.organization.fields.name }}</h5>
									<br />
								</span>
								<span v-if="
									organization.fields.img_publicid &&
									organization.fields.img_version
								">
									<br /><br />
									<cld-image cloudName="duppla" :publicId="organization.fields.img_publicid"
										:version="organization.fields.img_version" secure="true" class="mt-3 mb-3">
										<cld-transformation quality="auto" fetchFormat="auto" />
									</cld-image>
									<br /><br />
								</span>
								<button type="button" id="start-questionnaire" class="btn btn-primary"
									@click.prevent="startQuestionnaire" v-t="'validation.start_here_button'"></button>
							</p>
						</div>
					</div>
				</span>
				<span v-if="!validOrganization && !loadingMedicalRecord">
					<div class="row justify-content-center">
						<div class="col-12 text-center">
							<div class="section-title">
								<h3 class="mb-4" v-t="'validation.no_one_dies_of_cancer'"></h3>
								<p class="text-muted para-desc mx-auto mb-0" v-html="$t('validation.duppla_tool_description')"></p>
							</div>
						</div>
						<div class="col-12 text-center mt-5">
							<p class="para-desc mx-auto mb-0">
								<span v-t="'validation.service_invitation_only'"></span><br />
							</p>
						</div>
						<div class="col-lg-5 col-md-6 col-10 mt-sm-0 pt-2 pt-sm-0 text-center">
							<p class="mt-4" v-t="'validation.interested_in_checkup'"></p>
							<div class="row">
								<div class="col-lg-12 mt-3" id="hubspotForm" v-once></div>
							</div>
						</div>
					</div>
				</span>
			</div>
		</section>

		<section class="container--forms" v-if="validOrganizationAndStart">
			<div class="container" ref="wizard">
				<div class="row justify-content-center">
					<div class="col-lg-10 col-12">
						<div class="progress-box">
							<div class="title__progress--container">
								<h3 class="title__progress">
									{{ $t("progress.progress_title") }}
									{{ $t("progress.questionnaire_progress") }}
								</h3>
								<h3 class="title__progress--mobile">
									{{ $t("progress.progress_title") }}
								</h3>
								<h3 class="title__progress--step">
									{{ $t("progress.step") }}
									{{ wCurrentStep + 1 }}
									{{ $t("progress.of") }}
									{{ wTotalSteps + 1 }}
								</h3>
							</div>
							<div class="progress">
								<div class="progress-bar position-relative bg-primary" v-bind:style="{ width: wProgress + '%' }">
									<div class="progress-value d-block text-muted h6"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row justify-content-center">
					<div class="col-lg-10 col-12">
						<div class="login_page bg-white shadow rounded shadow-lg p-sm-1 p-md-4 p-lg-4 mb-5">
							<div class="card-body p-sm-1 questionnarie_content">
								<div class="row justify-content-center" v-if="loadingMedicalRecord">
									<b-spinner style="width: 3rem; height: 3rem" variant="primary"
										:label="$t('loading.loading_medical_record')"></b-spinner>
								</div>

								<form class="login-form form_init" :class="{
									visible:
										wStepName(wCurrentStep) === 'init' &&
										!loadingMedicalRecord,
								}" ref="form_init">
									<div class="row">
										<div class="col-md-12">
											<h4 class="greeting--text" v-if="wFirstStep">
												{{ greeting }}
											</h4>
											<p class="text-justify" v-html="wSteps[wCurrentStep].text"></p>
										</div>
									</div>
									<div class="container--inputs">
										<div class="row">
											<div class="col-md-12">
												<div class="form-group question">
													<div class="d-flex ">
														<label v-t="'form_init.email_label'"></label>
														<span class="text-danger"> *</span>
													</div>
													<div class="position-relative">
														<mail-icon class="fea icon-sm icons"></mail-icon>
														<input type="email" class="form-control question pl-5"
															:placeholder="$t('form_init.email_placeholder')" v-model="form.contact_email"
															id="contact_email" required />
														<small v-if="this.errorMail" style="color: red; font-size: 0.8rem">{{ this.errorMail }}</small>
													</div>
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-md-4">
												<div class="form-group question">
													<div class="d-flex">
														<label v-t="'form_init.age_label'"> </label><span class="text-danger"> *</span>
													</div>
													<div class="position-relative">
														<hash-icon class="fea icon-sm icons"></hash-icon>
														<input required type="number" class="form-control question pl-5" v-model="form.user_age"
															name="user_age" id="user_age" min="1" max="150" />
													</div>
												</div>
											</div>
											<div class="col-md-5">
												<div class="form-group question" id="patientSex">
													<div class="d-flex">
														<label v-t="'form_init.sex_label'"> </label><span class="text-danger"> *</span>
													</div>
													<div class="position-relative custom-control custom-radio">
														<input type="radio" id="female" name="patientSex" class="custom-control-input patientSex"
															value="female" v-model="form.patient_sex" required />
														<label class="custom-control-label" for="female" v-t="'form_init.female_option'"></label>
													</div>
													<div class="position-relative custom-control custom-radio">
														<input type="radio" id="male" name="patientSex" class="custom-control-input patientSex"
															value="male" v-model="form.patient_sex" required />
														<label class="custom-control-label" for="male" v-t="'form_init.male_option'"></label>
													</div>
												</div>
											</div>
										</div>
									</div>
								</form>

								<form class="login-form form_symptoms_step" :class="{
									visible: wStepName(wCurrentStep) === 'symptoms_step',
								}" ref="form_symptoms_step">
									<div class="row">
										<div class="col-md-12 mt-2 mb-4">
											<h4 class="title" v-if="wFirstStep">{{ greeting }}</h4>
											<p class="text-justify" v-html="wSteps[wCurrentStep].text"></p>
										</div>
									</div>
									<div class="row">
										<div class="col-md-12 row--input">
											<b-form-group class="question" label-cols-sm="6" label-cols-lg="4" content-cols-sm="6"
												content-cols-lg="7" id="symptom_concern" v-t="'form_symptoms_step.symptom_concern_label'">
											</b-form-group>
											<div class="options--checkbox">
												<div class="custom-control custom-radio custom-control-inline">
													<input type="radio" id="symptomConcernYes" name="symptom_concern" class="custom-control-input"
														value="yes" v-model="form.symptom_concern" required @change="symptomChange" />
													<label class="custom-control-label" for="symptomConcernYes"
														v-t="'form_symptoms_step.yes_option'"></label>
												</div>
												<div class="custom-control custom-radio custom-control-inline">
													<input type="radio" id="symptomConcernNo" name="symptom_concern" class="custom-control-input"
														value="no" v-model="form.symptom_concern" required @change="symptomChange" />
													<label class="custom-control-label" for="symptomConcernNo"
														v-t="'form_symptoms_step.no_option'"></label>
												</div>
											</div>
										</div>
										<span :class="{
											visible: form.symptom_concern === 'yes',
											hidden: form.symptom_concern !== 'yes',
										}">
											<div class="col-md-12 form-inline mb-4 mt-4">
												<p v-t="'form_symptoms_step.important_note'"></p>
												<p v-t="'form_symptoms_step.specify_symptoms'"></p>
												<p class="text-muted">
													<i v-t="'form_symptoms_step.symptoms_example'"></i>
												</p>
											</div>
											<span v-for="(item, index) in form.symptoms">
												<div class="col-md-12 form-inline mb-4" v-if="item.action != 'delete'">
													<label class="sr-only" :for="'sy1_' + index"
														v-t="'form_symptoms_step.symptom_placeholder'"></label>
													<input type="text" class="form-control question mb-2 mr-sm-2 mb-sm-0" :id="'sy1_' + index"
														v-bind:placeholder="$t('form_symptoms_step.symptom_placeholder')
															" required v-model="item.fields.description" style="min-width: 200px" />
													<label class="sr-only" :for="'sy2_' + index"></label>
													<vue-monthly-picker v-model="item.fields.date" :monthLabels="$t('form_symptoms_step.months_labels')
														" :placeHolder="$t('form_symptoms_step.month_year_placeholder')
															" dateFormat="MMM YYYY" selectedBackgroundColor="#2f55d4" required class="mr-2 mb-2 mb-sm-0"
														:max="currDate"></vue-monthly-picker>
													<a href="#" class="btn btn-icon btn-pills btn-outline-danger remove-item--btn"
														v-if="form.symptoms.length > 1" @click.prevent="
															removeFormListItem('symptoms', index)
															"><x-icon class="fea icon-sm"></x-icon></a>
												</div>
											</span>
											<div class="col-md-12 mb-4">
												<b-btn class="btn btn-primary ml-3" id="add-symptom"
													@click.prevent="addFormListItem('symptoms')">
													<span v-t="'form_symptoms_step.add_symptom_button'"></span>
												</b-btn>
											</div>
										</span>
									</div>
								</form>

								<form class="login-form form_ahf" :class="{ visible: wStepName(wCurrentStep) === 'ahf' }"
									ref="form_ahf">
									<div class="row">
										<div class="col-md-12 mt-2 mb-4">
											<h4 class="title" v-if="wFirstStep">{{ greeting }}</h4>
											<p class="text-justify" v-html="wSteps[wCurrentStep].text"></p>
										</div>
									</div>
									<div class="row">
										<div class="col-md-12 row--input">
											<b-form-group class="question" id="direct_relative" v-t="'form_ahf.family_cancer_history_label'">
											</b-form-group>
											<div class="options--checkbox">
												<div class="custom-control custom-radio custom-control-inline mr-3">
													<input type="radio" id="direct_relativeYes" name="direct_relative"
														class="custom-control-input" value="yes" v-model="form.direct_relative" required
														@change="directRelativeChange" />
													<label class="custom-control-label" for="direct_relativeYes"
														v-t="'form_ahf.yes_option'"></label>
												</div>
												<div class="custom-control custom-radio custom-control-inline">
													<input type="radio" id="direct_relativeNo" name="direct_relative" class="custom-control-input"
														value="no" v-model="form.direct_relative" required @change="directRelativeChange" />
													<label class="custom-control-label" for="direct_relativeNo"
														v-t="'form_ahf.no_option'"></label>
												</div>
												<div class="custom-control custom-radio custom-control-inline">
													<input type="radio" id="direct_relativeIdk" name="direct_relative"
														class="custom-control-input" value="idk" v-model="form.direct_relative" required
														@change="directRelativeChange" />
													<label class="custom-control-label" for="direct_relativeIdk"
														v-t="'form_ahf.idk_option'"></label>
												</div>
											</div>
										</div>
										<span v-if="form.direct_relative === 'yes'">
											<div class="col-md-12 form-inline mb-4 mt-4" v-t="'form_ahf.specify_relatives_label'"></div>
											<span v-for="(item, index) in form.direct_relatives">
												<div class="col-md-12 form-inline mb-4">
													<label class="sr-only" :for="'dr1_' + index" v-t="'form_ahf.cancer_detected_label'"></label>
													<b-select class="form-control question mb-2 mr-sm-2 mb-sm-0" :id="'dr1_' + index"
														v-model="item.fields.disease" required>
														<template #first>
															<b-select-option :value="null" disabled>--
																{{ $t("form_ahf.cancer_detected_label") }}
																--</b-select-option>
														</template>
														<b-select-option value="colon" v-t="'form_ahf.cancer_types.colon'"></b-select-option>
														<b-select-option value="endometrio"
															v-t="'form_ahf.cancer_types.endometrio'"></b-select-option>
														<b-select-option value="mama" v-t="'form_ahf.cancer_types.mama'"></b-select-option>
														<b-select-option value="prostata" v-t="'form_ahf.cancer_types.prostata'"></b-select-option>
														<b-select-option value="pancreas" v-t="'form_ahf.cancer_types.pancreas'"></b-select-option>
														<b-select-option value="ovario" v-t="'form_ahf.cancer_types.ovario'"></b-select-option>
														<b-select-option value="cancer-otro" v-t="'form_ahf.cancer_types.other'"></b-select-option>
													</b-select>

													<label class="sr-only" :for="'dr2_' + index" v-t="'form_ahf.who_label'"></label>
													<b-select class="form-control question mb-2 mr-sm-2 mb-sm-0" :id="'dr2_' + index"
														v-model="item.fields.relative" required>
														<template #first>
															<b-select-option :value="null" disabled>--
																{{ $t("form_ahf.who_label") }}
																--</b-select-option>
														</template>
														<b-select-option value="grandparent"
															v-t="'form_ahf.relatives.grandparent'"></b-select-option>
														<b-select-option value="father" v-t="'form_ahf.relatives.father'"></b-select-option>
														<b-select-option value="mother" v-t="'form_ahf.relatives.mother'"></b-select-option>
														<b-select-option value="uncle" v-t="'form_ahf.relatives.uncle'"></b-select-option>
														<b-select-option value="brother" v-t="'form_ahf.relatives.brother'"></b-select-option>
														<b-select-option value="sister" v-t="'form_ahf.relatives.sister'"></b-select-option>
														<b-select-option value="cousin" v-t="'form_ahf.relatives.cousin'"></b-select-option>
													</b-select>

													<label class="sr-only" :for="'dr4_' + index" v-t="'form_ahf.diagnosis_age_label'"></label>
													<b-select class="form-control question mb-2 mr-sm-2 mb-sm-0" :id="'dr4_' + index"
														v-model="item.fields.diagnosis_age" required>
														<template #first>
															<b-select-option :value="null" disabled>--
																{{ $t("form_ahf.diagnosis_age_label") }}
																--</b-select-option>
														</template>
														<b-select-option value="0-39" v-t="'form_ahf.diagnosis_ages.0-39'"></b-select-option>
														<b-select-option value="40-44" v-t="'form_ahf.diagnosis_ages.40-44'"></b-select-option>
														<b-select-option value="45-49" v-t="'form_ahf.diagnosis_ages.45-49'"></b-select-option>
														<b-select-option value="50+" v-t="'form_ahf.diagnosis_ages.50+'"></b-select-option>
													</b-select>

													<a href="#" class="btn btn-icon btn-pills btn-outline-danger remove-item--btn"
														v-if="form.direct_relatives.length > 1" @click.prevent="
															removeFormListItem('direct_relatives', index)
															">
														<x-icon class="fea icon-sm"></x-icon>
													</a>
												</div>
											</span>
											<div class="col-md-12 mb-4">
												<b-btn class="btn btn-primary" id="add_direct_relatives"
													@click.prevent="addFormListItem('direct_relatives')">
													<span v-t="'form_ahf.add_relative_button'"></span>
												</b-btn>
											</div>
										</span>
									</div>
								</form>

								<form class="login-form form_agine" :class="{ visible: wStepName(wCurrentStep) === 'agine' }"
									ref="form_agine">
									<div class="row">
										<div class="col-md-12 mt-2">
											<h4 class="title" v-if="wFirstStep">{{ greeting }}</h4>
											<p class="text-justify" v-html="wSteps[wCurrentStep].text"></p>
										</div>
									</div>
									<div class="row container--inputs">
										<div class="col-md-12 row--input" v-if="form.user_age >= 40">
											<b-form-group class="question" label-cols-sm="6" label-cols-lg="4" content-cols-sm="6"
												content-cols-lg="6" v-t="'form_agine.first_menstruation_age_label'">
											</b-form-group>
											<input required type="number" class="form-control question" placeholder=""
												v-model="form.f_menstruation_age" name="f_menstruation_age" id="f_menstruation_age" min="1"
												max="99" />
										</div>
										<div class="col-md-12 row--input">
											<b-form-group class="question" label-cols-sm="6" id="ivs" label-cols-lg="4" content-cols-sm="6"
												content-cols-lg="7" v-t="'form_agine.had_sex_label'">
											</b-form-group>
											<div class="options--checkbox">
												<div class="custom-control custom-radio custom-control-inline">
													<input type="radio" id="ivsYes" name="ivs" class="custom-control-input" value="yes"
														v-model="form.ivs" required />
													<label class="custom-control-label" for="ivsYes" v-t="'form_agine.yes_option'"></label>
												</div>
												<div class="custom-control custom-radio custom-control-inline">
													<input type="radio" id="ivsNo" name="ivs" class="custom-control-input" value="no"
														v-model="form.ivs" required />
													<label class="custom-control-label" for="ivsNo" v-t="'form_agine.no_option'"></label>
												</div>
											</div>
										</div>
										<div class="col-md-12 row--input" v-if="form.ivs === 'yes'">
											<b-form-group class="question" id="vag_bleeding" label-cols-sm="6" label-cols-lg="4"
												content-cols-sm="6" content-cols-lg="7" v-t="'form_agine.vaginal_bleeding_label'">
											</b-form-group>
											<div class="options--checkbox">
												<div class="custom-control custom-radio custom-control-inline">
													<input type="radio" id="vag_bleedingYes" name="vag_bleeding" class="custom-control-input"
														value="yes" v-model="form.vag_bleeding" required />
													<label class="custom-control-label" for="vag_bleedingYes"
														v-t="'form_agine.yes_option'"></label>
												</div>
												<div class="custom-control custom-radio custom-control-inline">
													<input type="radio" id="vag_bleedingNo" name="vag_bleeding" class="custom-control-input"
														value="no" v-model="form.vag_bleeding" required />
													<label class="custom-control-label" for="vag_bleedingNo" v-t="'form_agine.no_option'"></label>
												</div>
											</div>
										</div>
										<div class="col-md-12 row--input" v-if="form.user_age >= 40">
											<b-form-group class="question" label-cols-sm="6" label-cols-lg="4" content-cols-sm="6"
												content-cols-lg="4" v-t="'form_agine.last_menstruation_age_label'">
											</b-form-group>
											<input required type="number" class="form-control question" placeholder=""
												id="last_menstruation_age" v-model="form.l_menstruation_age" name="l_menstruation_age"
												min="form.f_menstruation_age" max="99" />
										</div>
										<div class="col-md-12 row--input" v-if="form.user_age >= 40">
											<b-form-group class="question" label-cols-sm="6" label-cols-lg="4" content-cols-sm="6"
												content-cols-lg="7" id="contraceptives" v-t="'form_agine.hormone_therapy_label'">
											</b-form-group>
											<div class="options--checkbox">
												<div class="custom-control custom-radio custom-control-inline">
													<input type="radio" id="contraceptivesYes" name="contraceptives" class="custom-control-input"
														value="yes" v-model="form.contraceptives" required />
													<label class="custom-control-label" for="contraceptivesYes"
														v-t="'form_agine.yes_option'"></label>
												</div>
												<div class="custom-control custom-radio custom-control-inline">
													<input type="radio" id="contraceptivesNo" name="contraceptives" class="custom-control-input"
														value="no" v-model="form.contraceptives" required />
													<label class="custom-control-label" for="contraceptivesNo"
														v-t="'form_agine.no_option'"></label>
												</div>
											</div>
										</div>
										<div class="col-md-12 row--input" v-if="form.ivs === 'yes' && form.user_age >= 40">
											<b-form-group class="question" label-cols-sm="6" label-cols-lg="4" content-cols-sm="6"
												content-cols-lg="7" id="pregnant_currently" v-t="'form_agine.currently_pregnant_label'">
											</b-form-group>
											<div class="options--checkbox">
												<div class="custom-control custom-radio custom-control-inline">
													<input type="radio" id="pregnant_currentlyYes" name="pregnant_currently"
														class="custom-control-input" value="yes" v-model="form.pregnant_currently" required />
													<label class="custom-control-label" for="pregnant_currentlyYes"
														v-t="'form_agine.yes_option'"></label>
												</div>
												<div class="custom-control custom-radio custom-control-inline">
													<input type="radio" id="pregnant_currentlyNo" name="pregnant_currently"
														class="custom-control-input" value="no" v-model="form.pregnant_currently" required />
													<label class="custom-control-label" for="pregnant_currentlyNo"
														v-t="'form_agine.no_option'"></label>
												</div>
											</div>
										</div>
										<div class="col-md-12 row--input" v-if="form.ivs === 'yes' && form.user_age >= 40">
											<b-form-group class="question" label-cols-sm="6" label-cols-lg="4" content-cols-sm="6"
												content-cols-lg="7" id="previous_pregnancies" v-t="'form_agine.previous_pregnancies_label'">
											</b-form-group>
											<div class="options--checkbox">
												<div class="custom-control custom-radio custom-control-inline">
													<input type="radio" id="previous_pregnanciesYes" name="previous_pregnancies"
														class="custom-control-input" value="yes" v-model="form.previous_pregnancies" required />
													<label class="custom-control-label" for="previous_pregnanciesYes"
														v-t="'form_agine.yes_option'"></label>
												</div>
												<div class="custom-control custom-radio custom-control-inline">
													<input type="radio" id="previous_pregnanciesNo" name="previous_pregnancies"
														class="custom-control-input" value="no" v-model="form.previous_pregnancies" required />
													<label class="custom-control-label" for="previous_pregnanciesNo"
														v-t="'form_agine.no_option'"></label>
												</div>
											</div>
										</div>
										<div class="col-md-12 row--input" v-if="
											form.previous_pregnancies === 'yes' &&
											form.ivs === 'yes' &&
											form.user_age >= 40
										">
											<b-form-group class="question" label-cols-sm="6" label-cols-lg="4" content-cols-sm="6"
												content-cols-lg="3" v-t="'form_agine.number_of_pregnancies_label'">
											</b-form-group>
											<input type="number" class="form-control question" :placeholder="$t('form_agine.number_of_pregnancies_placeholder')
												" id="previous_pregnancies_qty" v-model="form.previous_pregnancies_qty" required min="1" max="30" />
										</div>
										<div class="col-md-12 row--input" v-if="
											form.previous_pregnancies === 'yes' &&
											form.ivs === 'yes' &&
											form.user_age >= 40
										">
											<b-form-group class="question" label-cols-sm="6" label-cols-lg="4" content-cols-sm="6"
												content-cols-lg="7" id="pregnant_after_thirty"
												v-t="'form_agine.pregnancies_after_thirty_label'">
											</b-form-group>
											<div class="options--checkbox">
												<div class="custom-control custom-radio custom-control-inline">
													<input type="radio" id="pregnant_after_thirtyYes" name="pregnant_after_thirty"
														class="custom-control-input" value="yes" v-model="form.pregnant_after_thirty" required />
													<label class="custom-control-label" for="pregnant_after_thirtyYes"
														v-t="'form_agine.yes_option'"></label>
												</div>
												<div class="custom-control custom-radio custom-control-inline">
													<input type="radio" id="pregnant_after_thirtyNo" name="pregnant_after_thirty"
														class="custom-control-input" value="no" v-model="form.pregnant_after_thirty" required />
													<label class="custom-control-label" for="pregnant_after_thirtyNo"
														v-t="'form_agine.no_option'"></label>
												</div>
											</div>
										</div>
										<div class="col-md-12 row--input lactation_months" v-if="
											form.previous_pregnancies === 'yes' &&
											form.ivs === 'yes' &&
											form.user_age >= 40
										">
											<b-form-group class="question" label-cols-sm="6" label-cols-lg="4" content-cols-sm="6"
												content-cols-lg="3" v-t="'form_agine.lactation_months_label'">
											</b-form-group>
											<input type="number" class="form-control question" v-model="form.lactation_months"
												id="lactation_months" :placeholder="$t('form_agine.lactation_months_placeholder')
													" required min="0" max="600" />
											<small></small>
											<small tabindex="-1" class="form-text text-muted" v-t="'form_agine.lactation_example'"></small>
										</div>
										<div class="col-md-12 row--input" v-if="form.user_age >= 40">
											<b-form-group class="question" label-cols-sm="6" label-cols-lg="4" content-cols-sm="6"
												content-cols-lg="7" id="breast_mass" v-t="'form_agine.breast_mass_label'">
											</b-form-group>
											<div class="options--checkbox">
												<div class="custom-control custom-radio custom-control-inline">
													<input type="radio" id="breast_massYes" name="breast_mass" class="custom-control-input"
														value="yes" v-model="form.breast_mass" required />
													<label class="custom-control-label" for="breast_massYes"
														v-t="'form_agine.yes_option'"></label>
												</div>
												<div class="custom-control custom-radio custom-control-inline">
													<input type="radio" id="breast_massNo" name="breast_mass" class="custom-control-input"
														value="no" v-model="form.breast_mass" required />
													<label class="custom-control-label" for="breast_massNo" v-t="'form_agine.no_option'"></label>
												</div>
											</div>
										</div>
									</div>
								</form>

								<form class="login-form form_apnp" :class="{ visible: wStepName(wCurrentStep) === 'apnp' }"
									ref="form_apnp">
									<div class="row">
										<div class="col-md-12 mt-2 mb-4">
											<h4 class="title" v-if="wFirstStep">{{ greeting }}</h4>
											<p class="text-justify" v-html="wSteps[wCurrentStep].text"></p>
										</div>
									</div>
									<div class="row container--inputs">
										<div class="col-md-12 row--input">
											<b-form-group class="question" label-cols-sm="6" label-cols-lg="4" content-cols-sm="6"
												content-cols-lg="7" v-t="'form_apnp.smoke_label'" id="smoke">
											</b-form-group>
											<div class="options--checkbox">
												<div class="custom-control custom-radio custom-control-inline">
													<input type="radio" id="smokeYes" name="smoke" class="custom-control-input" value="yes"
														v-model="form.smoke" required />
													<label class="custom-control-label" for="smokeYes" v-t="'form_apnp.yes_option'"></label>
												</div>
												<div class="custom-control custom-radio custom-control-inline">
													<input type="radio" id="smokeNo" name="smoke" class="custom-control-input" value="no"
														v-model="form.smoke" required />
													<label class="custom-control-label" for="smokeNo" v-t="'form_apnp.no_option'"></label>
												</div>
											</div>
										</div>
										<div class="col-md-12 row--input" v-if="form.smoke === 'yes'">
											<b-form-group class="question" label-cols-sm="6" label-cols-lg="4" content-cols-sm="6"
												content-cols-lg="7" v-t="'form_apnp.smoke_years_label'">
											</b-form-group>
											<input type="number" class="form-control question"
												:placeholder="$t('form_apnp.smoke_years_placeholder')" v-model="form.smoke_years"
												id="smoke_years" required min="1" max="100" />
										</div>
										<div class="col-md-12 row--input" v-if="form.smoke === 'yes'">
											<b-form-group class="question" label-cols-sm="6" label-cols-lg="4" content-cols-sm="6"
												content-cols-lg="7" v-t="'form_apnp.smoke_qty_label'">
											</b-form-group>
											<input type="number" class="form-control question"
												:placeholder="$t('form_apnp.smoke_qty_placeholder')" v-model="form.smoke_qty_day"
												id="smoke_qty_day" required min="1" max="500" />
										</div>
										<div class="col-md-12 row--input" v-if="form.smoke === 'yes'">
											<b-form-group class="question" label-cols-sm="6" label-cols-lg="4" content-cols-sm="6"
												content-cols-lg="7" v-t="'form_apnp.ex_smoker_label'" id="ex_smoker">
											</b-form-group>
											<div class="options--checkbox">
												<div class="custom-control custom-radio custom-control-inline">
													<input type="radio" id="ex_smokerYes" name="ex_smoker" class="custom-control-input"
														value="yes" v-model="form.ex_smoker" required />
													<label class="custom-control-label" for="ex_smokerYes" v-t="'form_apnp.yes_option'"></label>
												</div>
												<div class="custom-control custom-radio custom-control-inline">
													<input type="radio" id="ex_smokerNo" name="ex_smoker" class="custom-control-input" value="no"
														v-model="form.ex_smoker" required />
													<label class="custom-control-label" for="ex_smokerNo" v-t="'form_apnp.no_option'"></label>
												</div>
											</div>
										</div>
										<div class="col-md-12 row--input" v-if="form.ex_smoker === 'yes'">
											<b-form-group class="question" label-cols-sm="6" label-cols-lg="4" content-cols-sm="6"
												content-cols-lg="7" v-t="'form_apnp.years_since_quitting_label'"
												description="$t('form_apnp.years_since_quitting_description')">
											</b-form-group>
											<input type="number" class="form-control question" :placeholder="$t('form_apnp.years_since_quitting_placeholder')
												" v-model="form.ex_somker_years" id="ex_somker_years" required min="1" max="100" />
										</div>
										<div class="col-md-12 row--input">
											<b-form-group class="question" label-cols-sm="6" label-cols-lg="4" content-cols-sm="6"
												content-cols-lg="7" id="cough_extended" v-t="'form_apnp.cough_extended_label'">
											</b-form-group>
											<div class="options--checkbox">
												<div class="custom-control custom-radio custom-control-inline">
													<input type="radio" id="cough_extendedYes" name="cough_extended" class="custom-control-input"
														value="yes" v-model="form.cough_extended" required />
													<label class="custom-control-label" for="cough_extendedYes"
														v-t="'form_apnp.yes_option'"></label>
												</div>
												<div class="custom-control custom-radio custom-control-inline">
													<input type="radio" id="cough_extendedNo" name="cough_extended" class="custom-control-input"
														value="no" v-model="form.cough_extended" required />
													<label class="custom-control-label" for="cough_extendedNo"
														v-t="'form_apnp.no_option'"></label>
												</div>
											</div>
										</div>
										<div class="col-md-12 row--input">
											<b-form-group class="question" label-cols-sm="6" label-cols-lg="4" content-cols-sm="6"
												content-cols-lg="7" id="cough_blood" v-t="'form_apnp.cough_blood_label'">
											</b-form-group>
											<div class="options--checkbox">
												<div class="custom-control custom-radio custom-control-inline">
													<input type="radio" id="cough_bloodYes" name="cough_blood" class="custom-control-input"
														value="yes" v-model="form.cough_blood" required />
													<label class="custom-control-label" for="cough_bloodYes" v-t="'form_apnp.yes_option'"></label>
												</div>
												<div class="custom-control custom-radio custom-control-inline">
													<input type="radio" id="cough_bloodNo" name="cough_blood" class="custom-control-input"
														value="no" v-model="form.cough_blood" required />
													<label class="custom-control-label" for="cough_bloodNo" v-t="'form_apnp.no_option'"></label>
												</div>
											</div>
										</div>
										<div class="col-md-12 row--input">
											<b-form-group class="question" label-cols-sm="6" label-cols-lg="4" content-cols-sm="6"
												content-cols-lg="7" id="alcohol" v-t="'form_apnp.alcohol_label'">
											</b-form-group>
											<div class="options--checkbox">
												<div class="custom-control custom-radio custom-control-inline">
													<input type="radio" id="alcoholYes" name="alcohol" class="custom-control-input" value="yes"
														v-model="form.alcohol" required />
													<label class="custom-control-label" for="alcoholYes" v-t="'form_apnp.yes_option'"></label>
												</div>
												<div class="custom-control custom-radio custom-control-inline">
													<input type="radio" id="alcoholNo" name="alcohol" class="custom-control-input" value="no"
														v-model="form.alcohol" required />
													<label class="custom-control-label" for="alcoholNo" v-t="'form_apnp.no_option'"></label>
												</div>
											</div>
										</div>
										<div class="col-md-12 row--input" v-if="form.alcohol === 'yes'">
											<b-form-group class="question" label-cols-sm="6" label-cols-lg="4" content-cols-sm="6"
												content-cols-lg="7" v-t="'form_apnp.alcohol_frequency_label'">
											</b-form-group>
											<b-select class="form-control question" v-model="form.alcohol_frequency" id="alcohol_frequency"
												required>
												<template #first>
													<b-select-option :value="null" disabled>--
														{{ $t("form_apnp.alcohol_frequency_label") }}
														--</b-select-option>
												</template>
												<b-select-option value="daily"
													v-t="'form_apnp.alcohol_frequency_options.daily'"></b-select-option>
												<b-select-option value="weekend"
													v-t="'form_apnp.alcohol_frequency_options.weekend'"></b-select-option>
												<b-select-option value="social"
													v-t="'form_apnp.alcohol_frequency_options.social'"></b-select-option>
											</b-select>
										</div>
										<div class="col-md-12 row--input" v-if="form.alcohol === 'yes'">
											<b-form-group class="question" label-cols-sm="6" label-cols-lg="4" content-cols-sm="6"
												content-cols-lg="7" v-t="'form_apnp.alcohol_amount_label'">
											</b-form-group>
											<input type="number" class="form-control question" :placeholder="$t('form_apnp.alcohol_amount_placeholder')
												" v-model="form.alcohol_amount" id="alcohol_amount" required min="1" max="100" />
										</div>
										<div class="col-md-12 row--input" v-if="form.alcohol === 'yes'">
											<b-form-group class="question" label-cols-sm="6" label-cols-lg="4" content-cols-sm="6"
												content-cols-lg="7" v-t="'form_apnp.alcohol_years_label'">
											</b-form-group>
											<input type="number" class="form-control question"
												:placeholder="$t('form_apnp.alcohol_years_placeholder')" v-model="form.alcohol_years"
												id="alcohol_years" required min="1" max="100" />
										</div>
										<div class="col-md-12 row--input">
											<b-form-group class="question" label-cols-sm="6" label-cols-lg="4" content-cols-sm="6"
												content-cols-lg="7" id="exercise" v-t="'form_apnp.exercise_label'">
											</b-form-group>
											<div class="options--checkbox">
												<div class="custom-control custom-radio custom-control-inline">
													<input type="radio" id="exerciseYes" name="exercise" class="custom-control-input" value="yes"
														v-model="form.exercise" required />
													<label class="custom-control-label" for="exerciseYes" v-t="'form_apnp.yes_option'"></label>
												</div>
												<div class="custom-control custom-radio custom-control-inline">
													<input type="radio" id="exerciseNo" name="exercise" class="custom-control-input" value="no"
														v-model="form.exercise" required />
													<label class="custom-control-label" for="exerciseNo" v-t="'form_apnp.no_option'"></label>
												</div>
											</div>
										</div>
										<div class="col-md-12 row--input" v-if="form.exercise === 'yes'">
											<b-form-group class="question" label-cols-sm="6" label-cols-lg="4" content-cols-sm="6"
												content-cols-lg="7" id="exercise_time" v-t="'form_apnp.exercise_time_label'">
											</b-form-group>
											<div class="options--checkbox">
												<div class="custom-control custom-radio custom-control-inline">
													<input type="radio" id="exercise_timeYes" name="exercise_time" class="custom-control-input"
														value="yes" v-model="form.exercise_time" required />
													<label class="custom-control-label" for="exercise_timeYes"
														v-t="'form_apnp.yes_option'"></label>
												</div>
												<div class="custom-control custom-radio custom-control-inline">
													<input type="radio" id="exercise_timeNo" name="exercise_time" class="custom-control-input"
														value="no" v-model="form.exercise_time" required />
													<label class="custom-control-label" for="exercise_timeNo" v-t="'form_apnp.no_option'"></label>
												</div>
											</div>
										</div>
										<div class="col-md-12 row--input">
											<b-form-group class="question" label-cols-sm="6" label-cols-lg="4" content-cols-sm="6"
												content-cols-lg="7" v-t="'form_apnp.diet_label'">
											</b-form-group>
											<b-select class="form-control question" v-model="form.eating_habit" required>
												<template #first>
													<b-select-option :value="null" id="eating_habit" disabled>--
														{{ $t("form_apnp.diet_label") }} --</b-select-option>
												</template>
												<b-select-option value="normal" v-t="'form_apnp.diet_options.normal'"></b-select-option>
												<b-select-option value="vegetarian" v-t="'form_apnp.diet_options.vegetarian'"></b-select-option>
												<b-select-option value="hyposodic" v-t="'form_apnp.diet_options.hyposodic'"></b-select-option>
												<b-select-option value="hypofat" v-t="'form_apnp.diet_options.hypofat'"></b-select-option>
												<b-select-option value="diabetic" v-t="'form_apnp.diet_options.diabetic'"></b-select-option>
											</b-select>
										</div>
										<div class="col-md-12 row--input" v-if="this.form.user_age > 44">
											<b-form-group class="question" label-cols-sm="6" label-cols-lg="4" content-cols-sm="6"
												content-cols-lg="7" id="evacuation_changes" v-t="'form_apnp.evacuation_changes_label'">
											</b-form-group>
											<div class="options--checkbox">
												<div class="custom-control custom-radio custom-control-inline">
													<input type="radio" id="evacuation_changesYes" name="evacuation_changes"
														class="custom-control-input" value="yes" v-model="form.evacuation_changes" required />
													<label class="custom-control-label" for="evacuation_changesYes"
														v-t="'form_apnp.yes_option'"></label>
												</div>
												<div class="custom-control custom-radio custom-control-inline">
													<input type="radio" id="evacuation_changesNo" name="evacuation_changes"
														class="custom-control-input" value="no" v-model="form.evacuation_changes" required />
													<label class="custom-control-label" for="evacuation_changesNo"
														v-t="'form_apnp.no_option'"></label>
												</div>
											</div>
										</div>
									</div>
								</form>

								<form class="login-form form_comorbidities" :class="{
									visible: wStepName(wCurrentStep) === 'comorbidities',
								}" ref="form_comorbidities">
									<div>
										<div>
											<h4 class="title" v-if="wFirstStep">{{ greeting }}</h4>
											<p v-html="wSteps[wCurrentStep].text"></p>
										</div>
									</div>
									<div class="container--inputs">
										<div class="two-cols two-cols--phone">
											<div class="question form-group">
												<div class="label--question--form_comorbidities">
													<label v-t="'form_comorbidities.weight_label'"></label><span class="text-danger"> *</span>
												</div>
												<small tabindex="-1" v-t="'form_comorbidities.weight_hint'"
													class="form-text text-muted"></small>
												<div class="position-relative">
													<hash-icon class="fea icon-sm icons"></hash-icon>
													<input type="number" class="form-control question pl-5" placeholder="ej. 65"
														v-model="form.weight" id="weight" required min="40" max="200" />
												</div>
											</div>
											<div class="question form-group">
												<div class="label--question--form_comorbidities">
													<label v-t="'form_comorbidities.height_label'"></label><span class="text-danger"> *</span>
												</div>
												<small tabindex="-1" v-t="'form_comorbidities.height_hint'"
													class="form-text text-muted"></small>
												<div class="position-relative">
													<hash-icon class="fea icon-sm icons"></hash-icon>
													<input type="number" class="form-control question pl-5" placeholder="ej. 175"
														v-model="form.height" id="height" required min="140" max="210" />
												</div>
											</div>
										</div>
										<div class="row--input comorbidities">
											<label class="question" id="diabetes" v-t="'form_comorbidities.diabetes_label'"></label>
											<div class="options--checkbox">
												<div class="custom-control custom-radio custom-control-inline">
													<input type="radio" id="diabetesYes" name="diabetes" class="custom-control-input" value="yes"
														v-model="form.diabetes" required />
													<label for="diabetesYes" class="custom-control-label"
														v-t="'form_comorbidities.yes_option'"></label>
												</div>
												<div class="custom-control custom-radio custom-control-inline">
													<input type="radio" id="diabetesNo" name="diabetes" class="custom-control-input" value="no"
														v-model="form.diabetes" required />
													<label for="diabetesNo" class="custom-control-label"
														v-t="'form_comorbidities.no_option'"></label>
												</div>
											</div>
										</div>
										<div class="row--input comorbidities">
											<label class="question" id="hiperlipidemia"
												v-t="'form_comorbidities.hyperlipidemia_label'"></label>
											<div class="options--checkbox">
												<div class="custom-control custom-radio custom-control-inline">
													<input type="radio" id="hiperlipidemiaYes" class="custom-control-input" name="hiperlipidemia"
														value="yes" v-model="form.hiperlipidemia" required />
													<label for="hiperlipidemiaYes" class="custom-control-label"
														v-t="'form_comorbidities.yes_option'"></label>
												</div>
												<div class="custom-control custom-radio custom-control-inline">
													<input type="radio" id="hiperlipidemiaNo" class="custom-control-input" name="hiperlipidemia"
														value="no" v-model="form.hiperlipidemia" required />
													<label for="hiperlipidemiaNo" class="custom-control-label"
														v-t="'form_comorbidities.no_option'"></label>
												</div>
											</div>
										</div>
									</div>
								</form>

								<form class="login-form form-end--container" :class="{ visible: wStepName(wCurrentStep) === 'end' }"
									ref="form_end">
									<div class="row justify-content-center container--inputs">
										<div class="col-md-12">
											<p class="" style="
													text-align: justify;
													text-justify: inter-word !important;
												" v-html="$t('form_end.congratulations_message')"></p>
										</div>
										<div class="col-md-10">
											<label class="question" v-t="'form_end.opt_advice_label'" id="opt_advice">
											</label>
											<div class="options--checkbox">
												<div class="custom-control custom-radio custom-control-inline">
													<input type="radio" id="opt_adviceYes" name="opt_advice" class="custom-control-input"
														value="yes" v-model="form.opt_advice" required />
													<label class="custom-control-label" for="opt_adviceYes" v-t="'form_end.yes_option'"></label>
												</div>
												<div class="custom-control custom-radio custom-control-inline">
													<input type="radio" id="opt_adviceNo" name="opt_advice" class="custom-control-input"
														value="no" v-model="form.opt_advice" required />
													<label class="custom-control-label" for="opt_adviceNo" v-t="'form_end.no_option'"></label>
												</div>
											</div>
											<small tabindex="-1" class="form-text text-muted" v-if="form.opt_advice === 'yes'"
												v-t="'form_end.contact_confirmation_message'"></small>
										</div>
										<div class="two-cols--phone col-md-12">
											<div v-if="form.opt_advice === 'yes'">
												<div class="form-group question">
													<div class="d-flex label--question--form_end">
														<label v-t="'form_end.contact_lada_label'"> </label>
														<span class="text-danger"> *</span>
													</div>
													<div class="position-relative">
														<phone-forwarded-icon class="fea icon-sm icons"></phone-forwarded-icon>
														<b-select class="form-control question pl-5" v-model="form.contact_phone_country"
															name="contact_phone_country" at-type="lookup" :options="countries" required
															text-field="textLada" value-field="value">
															<template #first>
																<b-select-option :value="null" disabled>--
																	{{ $t("form_end.contact_lada_label") }}
																	--</b-select-option>
															</template>
														</b-select>
													</div>
												</div>
											</div>
											<div v-if="form.opt_advice === 'yes'">
												<div class="form-group question">
													<div class="d-flex label--question--form_end">
														<label v-t="'form_end.contact_phone_label'">
														</label>
														<span class="text-danger"> *</span>
													</div>
													<div class="position-relative">
														<phone-icon class="fea icon-sm icons"></phone-icon>
														<input type="tel" class="form-control question pl-5" placeholder="Teléfono de 10 dígitos"
															v-model="form.contact_phone" id="contact_phone" name="contact_phone" required
															maxlength="10" minlength="10" pattern="[0-9]{10}" />
													</div>
												</div>
											</div>
										</div>
										<div class="col-md-12 mt-3">
											<p class="" style="
													text-align: justify;
													text-justify: inter-word !important;
												" v-html="$t('form_end.terms_conditions_message')"></p>
										</div>
										<div class="col-md-10 mt-3">
											<div class="form-group question">
												<div class="custom-control custom-checkbox">
													<input type="checkbox" class="custom-control-input" id="customCheck1" required />
													<label class="custom-control-label" for="customCheck1"
														v-html="$t('form_end.accept_terms_label')"></label>
												</div>
											</div>
										</div>
										<div class="col-md-10">
											<div class="form-group question">
												<div class="custom-control custom-checkbox">
													<input type="checkbox" class="custom-control-input" id="customCheck2" required />
													<label class="custom-control-label" for="customCheck2"
														v-html="$t('form_end.accept_privacy_label')"></label>
												</div>
											</div>
										</div>

										<div class="col-md-10">
											<div class="form-group question">
												<vue-recaptcha :sitekey="reCaptchaSiteKey" :loadRecaptchaScript="true"
													@verify="reCaptchaVerified" @expired="reCaptchaExpired" ref="recaptcha"></vue-recaptcha>
											</div>
										</div>
										<div class="col-md-10 mt-3" v-if="reCaptchaMissing">
											<div class="alert alert-danger" role="alert" v-t="'form_end.captcha_alert'"></div>
										</div>
									</div>
								</form>

								<hr />
								<div class="footer--form" v-if="!loadingMedicalRecord" :id="'footer--form' + wStepName(wCurrentStep)">
									<div class="button--step">
										<button :id="'back-' + wStepName(wCurrentStep)" @click.prevent="previousStep" :disabled="savingForm"
											v-if="!wFirstStep" class="previous_button--big">
											<arrow-left-icon class="fea icon-sm"></arrow-left-icon>
											<span v-t="'form_navigation.previous_button'"></span>
										</button>
										<button :id="'back-' + wStepName(wCurrentStep)" @click.prevent="previousStep" :disabled="savingForm"
											v-if="!wFirstStep">
											<arrow-left-icon class="icons"></arrow-left-icon>
										</button>
									</div>
									<div v-if="!wLastStep" class="button--step">
										<button :id="'next-' + wStepName(wCurrentStep)" @click.prevent="nextStep" :disabled="savingForm"
											class="next_button--big">
											<span v-t="'form_navigation.next_button'"></span>
											<arrow-right-icon class="fea icon-sm"></arrow-right-icon>
										</button>
										<button :id="'next-' + wStepName(wCurrentStep)" @click.prevent="nextStep" :disabled="savingForm">
											<arrow-right-icon class="icons"></arrow-right-icon>
										</button>
									</div>
									<div v-if="wLastStep" class="button--step processing_button">
										<button id="ver-resultados" @click.prevent="submit" :disabled="savingForm">
											{{
												savingForm
													? $t("form_navigation.processing_button")
													: $t("form_navigation.view_results_button")
											}}
										</button>
									</div>
								</div>
								<div>
									<div v-if="errorForm">
										<div class="alert alert-danger" role="alert" v-t="'form_navigation.error_message'"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!--end row-->
			</div>
			<!--end container-->
		</section>
	</div>
</template>
